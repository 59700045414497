import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const HomePageScrollContainer = styled.div`
  overflow: scroll;
  width: 100%;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
HomePageScrollContainer.displayName = "S.HomePageScrollContainer"

export const HomePageScrollContainerScroller = styled.div`
  overflow: hidden;
  width: 820px;
`
HomePageScrollContainerScroller.displayName = "S.HomePageScrollContainerScroller"

export const HomePageScrollContainerScrollerStory = styled.div`
  height: auto;
  text-align: center;
  float: left;
`
HomePageScrollContainerScrollerStory.displayName =
  "S.HomePageScrollContainerScrollerStory"

export const HomePageScrollContainerScrollerStoryCard = styled.div`
  margin: 0.5rem;
  cursor: pointer;
  background-image: ${C.v2Theme.gradients.gradients2};
  border-radius: 14px;
  overflow: hidden;
  width: 100px;
  img {
    height: 56px;
    width: 100%;
    object-fit: cover;
  }
`
HomePageScrollContainerScrollerStoryCard.displayName =
  "S.HomePageScrollContainerScrollerStoryCard"

export const HomePageScrollContainerScrollerStoryCardImg = styled.img`
  height: 56px;
  width: 100%;
  object-fit: cover;
`
HomePageScrollContainerScrollerStoryCardImg.displayName =
  "S.HomePageScrollContainerScrollerStoryCardImg"

export const HomePageScrollContainerScrollerStoryCardH6 = styled.h6`
  font-weight: ${C.v2Theme.fontWeights.regular};
  font-size: ${C.v2Theme.fontSizes.caption};
  b {
    font-weight: ${C.v2Theme.fontWeights.regular};
  }
`
HomePageScrollContainerScrollerStoryCardH6.displayName =
  "S.HomePageScrollContainerScrollerStoryCardH6"

export const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
ColumnFlexContainer.displayName = "S.ColumnFlexContainer"
