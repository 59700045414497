import styled from "styled-components"
import {
  boldFontWeight,
  smallScreen,
  thinFontWeight,
} from "@temp/@next/globalStyles/constants"

export const ProductsByGoal = styled.div``
ProductsByGoal.displayName = "S.ProductsByGoal"

export const ProductsByGoalBanner = styled.div`
  position: relative;
  height: 156px;
  img {
    height: 100%;
    position: absolute;
    object-fit: cover;
    width: 100%;
  }
`
ProductsByGoalBanner.displayName = "S.ProductsByGoalBanner"

export const ProductsByGoalBannerH4 = styled.h4`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: end;
  padding: 2rem 6rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: ${thinFontWeight};
  line-height: 2.5rem;
  @media (max-width: ${smallScreen}px) {
    text-align: center;
    padding: 0;
  }
`
ProductsByGoalBannerH4.displayName = "S.ProductsByGoalBannerH4"

export const ProductsByGoalBannerH4b = styled.b`
  font-size: 2.5rem;
  font-weight: ${boldFontWeight};
`
ProductsByGoalBannerH4b.displayName = "S.ProductsByGoalBannerH4b"
