import styled, { keyframes } from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

const reveal = keyframes`
  0%{
    transform: translate(-372px, 0px);
  }
  100%{
    transform: translate(0px, 0px);
  }
`
const hide = keyframes`
  0%{
    transform: translate(0px, 0px);
  }
  100%{
    transform: translate(-372px, 0px);
  }
`
export const OrderToast = styled.div<{ show: boolean }>`
  width: 371px;
  height: auto;
  margin: 0 14px 0 0;
  padding: 8px 0px 0;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background-color: #fcf4db;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px 6px 15px 3px rgb(0 0 0 / 25%);
  z-index: 9999999999;

  @media (max-width: ${C.smallScreen}px) {
    width: 100%;
    border-bottom-left-radius: revert;
    border-bottom-right-radius: revert;
    top: initial;
    bottom: 0;
  }
  animation: ${(props) => (props.show ? hide : reveal)} 1 1s;
`
OrderToast.displayName = "S.OrderToast"

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 8px 0;
`
FlexContainer.displayName = "S.OrderToast"

export const ImgDiv = styled.div`
  width: 60px;
  height: 70px;
  position: relative;
`

ImgDiv.displayName = "S.ImgDiv"

export const SubGrayText = styled.p`
  font-size: ${C.v2Theme.fontSizes.caption};
  color: ${C.colorDarkGray1};
`
SubGrayText.displayName = "S.SubGrayText"

export const SubBlkText = styled.p`
  color: #000000;
  font-weight: 600;
`
SubBlkText.displayName = "S.SubBlkText"

export const ShipStatus = styled.span`
  text-transform: capitalize;
  color: #44a228;
  font-size: 12px;
  font-weight: 500;
`
ShipStatus.displayName = "S.ShipStatus"

export const ShipTrack = styled.a`
  text-decoration: underline;
  font-size: 13px;
  font-weight: 500;
  text-align: right;
  margin-left: 30px;
  cursor: pointer;
`
ShipTrack.displayName = "S.ShipTrack"

export const ShipTrackDiv = styled.div`
  position: absolute;
  right: 15px;
`
ShipTrackDiv.displayName = "S.ShipTrackDiv"

export const CloseIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`
CloseIcon.displayName = "S.CloseIcon"

export const HorizontalDivider = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid ${C.v2Theme.backgroundColors.gray3};
`

export const MyOrdersButton = styled.a`
  text-decoration: underline;
  font-weight: 600;
  font-size: 14px;
  padding: 0 12px 12px;
  display: block;
  margin-top: 8px;
`
