import styled from "styled-components"

const overlayBackgroundSelect: { [key: string]: any } = {
  overLayBackGround: {
    grayOverlayBackGround:
      "linear-gradient(to bottom, rgba(255, 255, 255, 0.27), #ffffff)",
    grayOverlayBackGroundv2:
      "linear-gradient(to right, rgba(250, 252, 255, 0) -9%, #fefeff 76%)",
    grayOverlayBackGroundv3:
      "linear-gradient(to right, rgba(250, 252, 255, 0) 35%, #fefeff 90%)",
    grayOverLayLefttoRight:
      "linear-gradient(to right, rgba(250, 252, 255, 0.5), rgba(250, 252, 255, 0))",
    grayOverLayRighttoLeft:
      "linear-gradient(to left, rgba(250, 252, 255, 0), rgba(250, 252, 255, 0.5))",
    gradientToRightDark:
      "linear-gradient(to right, rgba(250, 252, 255, 0), rgba(250, 252, 255, 1))",
    gradientToLeftDark:
      "linear-gradient(to left, rgba(250, 252, 255, 0), rgba(250, 252, 255, 1))",
    gradientLeftToRight:
      "linear-gradient(to right, rgba(250, 252, 255, 0.8), rgba(250, 252, 255, 0))",
  },
}

export const FadedOverlayContainer = styled.div<{
  position?: string
  left?: string
  top?: string
  right?: string
  bottom?: string
  height?: string
  width?: string
  background: string
  zIndex?: number
  brandsfeatured?: boolean
}>`
  ${(props) => `
      position: ${props.position};
      left: ${props.left};
      top: ${props.top};
      right: ${props.right};
      bottom: ${props.bottom};
      height: ${props.height};
      width: ${props.width};
      z-index: ${props.zIndex};
      background: ${overlayBackgroundSelect.overLayBackGround[props.background]};
      pointer-events:${props.brandsfeatured ? "none" : "unset"}
    `};
`
FadedOverlayContainer.displayName = "S.FadedOverlayContainer"
