import { getHomePageProps } from "@app/staticProps/home"
import { HomePage } from "@components/templates/HomePage/HomePage"
import { HomePageNextProps } from "@components/templates/HomePage/types"
import React from "react"

const Home = (props: HomePageNextProps) => <HomePage {...props} />

// eslint-disable-next-line import/no-default-export,import/no-unused-modules
export default Home

// eslint-disable-next-line import/no-unused-modules
export const getStaticProps = getHomePageProps
