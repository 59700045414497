import { useReactiveVar } from "@apollo/client"
import { Container } from "@components/atoms/Container"
import { FadedOverlay } from "@components/atoms/FadedOverlay"
import { TrendingHeading } from "@components/atoms/TrendingHeading"
import { withErrorBoundary } from "@sentry/nextjs"
import { useInView } from "@temp/@next/hooks/useInView"
import { getMedia } from "@temp/localStates/media"
import React, { memo, useCallback, useState } from "react"

import { GlobalMediaQueryResultInterface } from "../../../../core/utils"
import { CustomImage } from "../CustomImage"
import { ErrorFallbackUi } from "../../molecules/ErrorFallbackUi/ErrorFallbackUi"
import * as S from "./styles"

export interface BrandsFeaturedItem {
  backgroundImageUrl?: string
  logoImageUrl?: string
  link: string
}
interface BrandsFeaturedProps {
  widthNumberArray: {
    num: number
    arr: any
    updatedBrands: any
  }
}

const BrandsFeatured: React.FC<BrandsFeaturedProps> = ({ widthNumberArray }) => {
  const matches: GlobalMediaQueryResultInterface = useReactiveVar(getMedia)
  const [visible, setVisible] = useState(false)
  const callback = useCallback(() => {
    setVisible(true)
  }, [])
  const brandsref = useInView({
    threshold: 0.5,
    callback,
  })
  const array = []
  for (const {
    node: { logo },
  } of widthNumberArray.updatedBrands) {
    for (const value of widthNumberArray.arr) {
      if (logo && value.url === logo.url) {
        array.push((value.width / value.height) * 30)
      }
    }
  }
  return (
    <div ref={brandsref}>
      <Container backgroundColor="none">
        <S.CustomCardContainer smallScreen={!matches.large}>
          <FadedOverlay
            position="absolute"
            height="100%"
            width={matches.small ? "70px" : "100px"}
            background={
              matches.small ? "grayOverlayBackGroundv3" : "grayOverlayBackGroundv2"
            }
            top="0px"
            right="0px"
            zIndex={1}
            brandsfeatured
          />
          <S.FeaturedBrands smallScreen={!matches.large}>
            <S.Heading>
              <TrendingHeading heading="Featured Brands" />
            </S.Heading>
            <S.FeaturedBrandImageUL
              desktopWidth={widthNumberArray.num / 3}
              animate={!matches.large && visible}
            >
              {widthNumberArray.updatedBrands.map(
                ({ node: { id, link, logo, name } }, key) => (
                  <S.FeaturedBrandImageList smallScreen={!matches.large} key={id}>
                    <S.LinkTag href={link} smallScreen={!matches.large}>
                      <S.ImageContainer>
                        <CustomImage
                          width={array[key] || 128}
                          height={30}
                          url={logo ? logo.url : "/images/Product/no-photo.svg"}
                          className="zoomingImage"
                          alt={`${name} logo`}
                        />
                      </S.ImageContainer>
                    </S.LinkTag>
                  </S.FeaturedBrandImageList>
                )
              )}
            </S.FeaturedBrandImageUL>
          </S.FeaturedBrands>
        </S.CustomCardContainer>
      </Container>
    </div>
  )
}

const MemoizedBrandsFeatured = memo(BrandsFeatured)
export const BrandsFeaturedWithEB = withErrorBoundary(MemoizedBrandsFeatured, {
  fallback: ({ error }) => (
    <ErrorFallbackUi componentName="BrandsFeatured" error={error} />
  ),
})
