import { gql } from "@apollo/client"

export const trendingProductIds = gql`
  query TrendingProductIds {
    shop {
      homepageCollection {
        id
        purchasables(first: 20) {
          edges {
            node {
              id
              product {
                id
                recommendationLevel
                lastPurchasedDate
                noOfTimesPurchased
                ratingsCount
              }
              comboProduct {
                id
              }
            }
          }
        }
      }
    }
  }
`
