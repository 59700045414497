import { useQuery, useReactiveVar } from "@apollo/client"
import { userGlobalState } from "@app/globalStates/User/userGlobalState"
import {
  GetUserOrders,
  GetUserOrders_me_orders_edges_node,
} from "@components/atoms/OrderShippedToast/types/GetUserOrders"
import { withErrorBoundary } from "@sentry/nextjs"
import Link from "@temp/core/link"
import { isDocumentComplete } from "@temp/localStates/documentComplete"
import dynamic from "next/dynamic"
import React, { useEffect, useState } from "react"
import { OrderOnlineShipmentDeliveryStatusChoices, OrderStatus } from "types/globalTypes"
import { ErrorFallbackUi } from "../../molecules/ErrorFallbackUi/ErrorFallbackUi"

import { getLatestOrderQuery } from "../OrderShippedToast/queries"
import { MyOrdersButton, OrderToast } from "../OrderShippedToast/styles"

const OrderShippedToast = dynamic<{
  order: GetUserOrders_me_orders_edges_node
  setHideNotification: React.Dispatch<React.SetStateAction<boolean>>
  setshowNotification: React.Dispatch<React.SetStateAction<boolean>>
}>(
  () =>
    import("@components/atoms/OrderShippedToast").then(
      (mod) => mod.OrderShippedToast
    ),
  {
    ssr: false,
  }
)

const LatestOrder: React.FC = () => {
  // Re-render this component when user changes
  const { user } = useReactiveVar(userGlobalState)
  // Load on 0th tick
  const load = useReactiveVar(isDocumentComplete)
  // fetch first 3 orders to show go to orders CTA in toast
  const variables = { pageSize: 3, after: "" }
  const { data, loading } = useQuery<GetUserOrders>(getLatestOrderQuery, {
    variables,
    skip: !user,
    fetchPolicy: "network-only",
  })

  const [showNotification, setshowNotification] = useState(false)
  const [hideNotification, setHideNotification] = useState(false)

  // filter those orders where at least one shipment is not delivered because we are not
  // showing delivered orders in toast
  const filteredEdges = data?.me?.orders?.edges.filter((edge) => {
    const order = edge.node
    const isCancelled = order.status === OrderStatus.CANCELED
    const allDelivered =
      order?.shipments &&
      order?.shipments?.length > 0 &&
      order.shipments?.every(
        (shipment) =>
          shipment?.deliveryStatus === OrderOnlineShipmentDeliveryStatusChoices.DELIVERED
      )
    return !isCancelled && !allDelivered
  })

  useEffect(() => {
    const localStorageDate = localStorage.getItem("orderShippedToast")
    const dateDiff =
      (Date.now() - Number(localStorageDate)) / (1000 * 60 * 60 * 24) >= 1
    if (dateDiff) {
      // reset local storage date
      localStorage.setItem("orderShippedToast", "")
      setshowNotification(true)
    }
  }, [])

  return (
    <div>
      {showNotification &&
        load &&
        user &&
        !loading &&
        data &&
        filteredEdges &&
        filteredEdges.length > 0 && (
          <OrderToast show={hideNotification}>
            {filteredEdges.slice(0, 2).map((filteredEdge) => (
              <OrderShippedToast
                setshowNotification={setshowNotification}
                setHideNotification={setHideNotification}
                key={filteredEdge.node.id}
                order={filteredEdge.node}
              />
            ))}
            {filteredEdges.length > 2 && (
              <Link href="/me/orders">
                <MyOrdersButton>My Orders</MyOrdersButton>
              </Link>
            )}
          </OrderToast>
        )}
    </div>
  )
}

export const LatestOrderWithEB = withErrorBoundary(LatestOrder, {
  fallback: ({ error }) => (
    <ErrorFallbackUi componentName="LatestOrder" error={error} />
  ),
})
