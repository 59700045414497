import React from "react"
import Head from "next/head"

import { useRouter } from "next/router"

import { MetaWrapperProps } from "./types"

export const MetaWrapper: React.FC<MetaWrapperProps> = ({
  title,
  description,
  openGraph,
  twitter,
  customMeta,
  pagination,
  googleStructuredData,
}) => {
  const router = useRouter()
  const defaultData = {
    title:
      "Buyceps - India's #1 Authentic Online Sports Nutrition & Supplements Store",
    description:
      "India's most trusted Online store for Authentic Bodybuilding Health Supplements, Diet Sports Nutrition Products for all Fitness Levels. Buy Online Authentic proteins & vitamins at best price from biggest brands in India. Free Shipping all India.",
  }

  return (
    <>
      <Head>
        <title>{title || defaultData.title}</title>
        <meta name="title" content={title || defaultData.title} />
        <meta name="description" content={description || defaultData.description} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#FFD400" />
        {(!pagination || !pagination?.paginated) && (
          <link rel="canonical" href={`https://buyceps.com${router.asPath}`} />
        )}
        {openGraph &&
          openGraph.map((property) => (
            <meta
              key={property.property}
              property={property.property}
              content={property.content}
            />
          ))}
        {twitter &&
          twitter.map((property) => (
            <meta
              key={property.property}
              property={property.property}
              content={property.content}
            />
          ))}
        {customMeta &&
          customMeta.map((property) => (
            <meta
              key={property.property}
              property={property.property}
              content={property.content}
            />
          ))}
        {pagination && pagination.paginated && (
          <link
            rel="canonical"
            href={`https://buyceps.com${router.asPath.slice(0, -7)}`}
          />
        )}
        {pagination && pagination.paginated && pagination.pageno !== 1 && (
          <link
            rel="prev"
            href={`https://buyceps.com${router.asPath.slice(0, -6)}page/${pagination.pageno - 1
              }`}
          />
        )}
        {pagination && pagination.paginated && pagination.next && (
          <link
            rel="next"
            href={`https://buyceps.com${router.asPath.slice(0, -6)}page/${pagination.pageno + 1
              }`}
          />
        )}
        {googleStructuredData && (
          <script type="application/ld+json">{googleStructuredData}</script>
        )}
      </Head>
    </>
  )
}
