import { MiniBanners_bannerList_mobileSize } from "@temp/@next/staticProps/home/types/MiniBanners"
import Link from "@temp/core/link"
import React from "react"
import { CustomImage } from "../../atoms/CustomImage"
import * as S from "./styles"

export interface CarouselCardProps {
  image: string
  alt: string
  url: string | null
  size: MiniBanners_bannerList_mobileSize
  preload: boolean
}

export const CarouselCard: React.FC<CarouselCardProps> = (
  props: CarouselCardProps
) => {
  const { image, alt, url, size, preload } = props

  const CarouselCardImage = (
    <S.CarouselCardContainer height={size.height}>
      <CustomImage
        priority={preload}
        url={image}
        alt={alt}
        layout="fill"
        objectFit="cover"
      />
    </S.CarouselCardContainer>
  )

  return (
    <>
      {url ? (
        <Link href={url}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>{CarouselCardImage}</a>
        </Link>
      ) : (
        CarouselCardImage
      )}
    </>
  )
}
