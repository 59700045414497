import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const Carousel = styled.div`
  width: 100%;
  z-index: 1;
  .homebanner-slider {
    display: flex;
    flex-direction: column;
  }
  .swiper-pagination-clickable {
    order: 2;
    position: unset;
    margin-top: 5px;
  }
  @media (max-width: ${C.smallScreen}px) {
    .swiper-pagination {
      display: none;
    }
  }
`

export const CarouselContainer = styled.div<{
  height: number
}>`
  margin: 0 auto;
  background-image: linear-gradient(to bottom, #fefeff, #f8faff 100%);
  max-width: 1150px;
  height: ${(props) => props.height}px;
  @media (max-width: ${C.smallScreen}px) {
    box-shadow: ${C.v2Theme.shadows.shadow5};
  }
`

export const CarouselContainerWrapper = styled.div<{
  height: number
}>`
  height: ${(props) => props.height}px;
  max-width: 1150px;
`
