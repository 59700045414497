import { gql } from "@apollo/client"

export const getLatestOrderQuery = gql`
  query GetUserOrders($pageSize: Int, $after: String) {
    me {
      id
      orders(first: $pageSize, after: $after) {
        edges {
          node {
            id
            number
            status
            lines {
              id
              firstImage {
                id
                alt
                url
              }
            }
            shipments {
              id
              deliveryStatus
              courierHandler
              otherCourier {
                id
                courierName
                courierOrderId
                courierAwb
                trackingUrl
              }
              shiprocketTrackingData {
                shipmentTrack {
                  currentStatus
                }
                shipmentTrackActivities {
                  activity
                  location
                  date(format: "%ds %B %Y, %-I:%-M %p") {
                    value
                  }
                  status
                }
              }
              fulfillments {
                id
                shippingDate
                lines {
                  id
                  quantity
                  orderLine {
                    id
                    quantity
                    order {
                      id
                      number
                    }
                  }
                }
              }
            }
          }
          cursor
        }
      }
    }
  }
`
