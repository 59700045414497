import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const ProductsByExclusiveListItem = styled.div`
  height: 100%;
  background-image: linear-gradient(to bottom, #fefeff, #f8faff 100%);
  border-radius: ${C.v2Theme.borderRadius.borderRadius1};
  text-align: center;
  position: relative;
  /* transition: box-shadow 0.5s; */
  /* box-shadow: ${C.v2Theme.shadows.shadow4}; */
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: ${C.v2Theme.borderRadius.borderRadius1};
  }
`

export const ProductsByExclusiveListItemLogo = styled.div`
  height: 75px;
  width: 25%;
  position: absolute;

  img {
    object-fit: cover;
  }
`
