import { useReactiveVar } from "@apollo/client"
import { Container } from "@components/atoms/Container"
import { TrendingHeading } from "@components/atoms/TrendingHeading"
import { DesktopSliderProps } from "@components/organisms/DesktopSlider/types"
import { MobileSliderProps } from "@components/organisms/MobileSlider/types"
import Link from "@temp/core/link"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import React, { memo } from "react"

import * as S from "./styles"

interface ProductCardsSliderProps {
  listId?: string
  title: string
  productIds: any[]
  arrowId?: number
  href?: string
  subheading: boolean
}

const MemoizedDesktopSlider = dynamic<DesktopSliderProps>(() =>
  import(
    /* webpackChunkName: "MemoizedDesktopSlider" */
    "@temp/@next/components/organisms/DesktopSlider/DesktopSlider"
  ).then((mod) => mod.MemoizedDesktopSlider)
)

const MemoizedMobileSlider = dynamic<MobileSliderProps>(() =>
  import(
    /* webpackChunkName: "MemoizedMobileSlider" */
    "@temp/@next/components/organisms/MobileSlider/MobileSlider"
  ).then((mod) => mod.MemoizedMobileSlider)
)

const ProductCardsSlider: React.FC<ProductCardsSliderProps> = ({
  listId,
  title,
  productIds,
  subheading,
  arrowId,
  href,
}) => {
  const matches: GlobalMediaQueryResultInterface = useReactiveVar(getMedia)
  const router = useRouter()
  let marginTop = matches.small ? 52 : 76
  if (router.pathname === "/product/[name]/[id]") {
    marginTop = matches.small ? 0 : 52
  }

  return (
    <S.ProductCardsSlider marginTop={marginTop}>
      <Container backgroundColor="none">
        <S.Spacer>
          {href ? (
            <Link href={href!}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>
                <TrendingHeading heading={title} />
                {matches.small && subheading && (
                  <S.ExploreAll>Explore all</S.ExploreAll>
                )}
              </a>
            </Link>
          ) : (
            <>
              <TrendingHeading heading={title} />
              {matches.small && subheading && (
                <S.ExploreAll>Explore all</S.ExploreAll>
              )}
            </>
          )}
        </S.Spacer>
        {
          // Mobile Slider
          matches.small ? (
            <MemoizedMobileSlider
              productIds={productIds}
              listId={listId}
              title={title}
              trackable
              eventName="view_item_list"
            />
          ) : (
            // Desktop Slider
            <MemoizedDesktopSlider
              productIds={productIds}
              arrowId={arrowId!}
              listId={listId}
              title={title}
              trackable
              eventName="view_item_list"
            />
          )
        }
      </Container>
    </S.ProductCardsSlider>
  )
}

ProductCardsSlider.defaultProps = {
  arrowId: undefined,
  href: undefined,
}

export const MemoizedProductCardsSlider = memo(ProductCardsSlider)
