import styled from "styled-components"

export const TrendingWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  column-gap: 10px;
  padding-right: 10px;
  padding-left: 10px;
  scroll-padding-left: 10px;
  padding-top: 31px;
`

export const TrendingSlide = styled.div`
  scroll-snap-align: start;
  position: relative;
  scroll-behavior: smooth;
  flex-shrink: 0;
  border-radius: 14px;
  z-index: 1;
  background-image: linear-gradient(to bottom, #fefeff, #f8faff 100%);
`
