import { useApolloClient } from "@apollo/client"
import { miniBannersQuery } from "@temp/@next/staticProps/home/query"
import { MiniBanners } from "@temp/@next/staticProps/home/types/MiniBanners"
import {
  getGraphqlIdFromDBId,
  GlobalMediaQueryResultInterface,
} from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import React from "react"
import { withErrorBoundary } from "@sentry/nextjs"
import { Container } from "../../atoms/Container"
import { Spacer } from "../../atoms/Spacer"
import { HomepageContainerPadding } from "../../templates/HomePage/styles"
import { HomepageSectionHeader } from "../../molecules/HomepageSectionHeader/HomepageSectionHeader"
import { MemoizedCarousel } from "../BannerCarousel/BannerCarousel"
import { BannerSlider } from "../BannerSlider/BannerSlider"
import { ErrorFallbackUi } from "../../molecules/ErrorFallbackUi/ErrorFallbackUi"

interface ActiveBannerProps {
  id: number
  preload: boolean
}

const ActiveBanner: React.FC<ActiveBannerProps> = (props: ActiveBannerProps) => {
  const { id, preload } = props
  const apolloClient = useApolloClient()
  const matches: GlobalMediaQueryResultInterface = getMedia()

  const activeBannerData = apolloClient.readQuery<MiniBanners>({
    query: miniBannersQuery,
    variables: {
      id: getGraphqlIdFromDBId(id!.toString(), "BannerList"),
    },
  })

  const bannerId = activeBannerData?.bannerList?.id
  const name = activeBannerData?.bannerList?.name
  const description = activeBannerData?.bannerList?.description
  const bannerList = activeBannerData?.bannerList?.lines!
  const mobileSize = activeBannerData?.bannerList?.mobileSize
  const mobileCarousel = activeBannerData?.bannerList?.mobileSize?.carousel
  const desktopSize = activeBannerData?.bannerList?.desktopSize
  const desktopCarousel = activeBannerData?.bannerList?.desktopSize?.carousel
  const showHeading = activeBannerData?.bannerList?.showNameOnStorefront

  // sanitizing seperately because a banner list for mobile may not have desktop image
  const sanitizedBannerListMobile = bannerList.filter(
    (banner) => banner?.mobileImage
  )
  const sanitizedBannerListDesktop = bannerList.filter(
    (banner) => banner?.desktopImage
  )

  const bannerHeading = (
    <HomepageContainerPadding>
      <HomepageSectionHeader heading={name} subHeading={description} />
      <Spacer spacerStyle="spacerExtra" />
    </HomepageContainerPadding>
  )

  const renderBanner = () => {
    if (
      (matches.small &&
        mobileSize &&
        mobileCarousel &&
        sanitizedBannerListMobile?.length > 0) ||
      (!matches.small &&
        desktopSize &&
        desktopCarousel &&
        sanitizedBannerListDesktop.length > 0)
    ) {
      return (
        <>
          {showHeading && bannerHeading}
          <HomepageContainerPadding>
            <MemoizedCarousel
              desktopSize={desktopSize!}
              mobileSize={mobileSize!}
              bannerList={
                matches.small
                  ? sanitizedBannerListMobile!
                  : sanitizedBannerListDesktop!
              }
              name={name!}
              preload={preload}
            />
          </HomepageContainerPadding>
        </>
      )
    }

    if (
      !mobileCarousel &&
      !desktopCarousel &&
      ((matches.small && mobileSize && sanitizedBannerListMobile.length > 0) ||
        (!matches.small && desktopSize && sanitizedBannerListDesktop.length > 0))
    ) {
      return (
        <>
          {showHeading && bannerHeading}
          <BannerSlider
            id={bannerId!}
            mobileSize={mobileSize!}
            desktopSize={desktopSize!}
            bannerList={
              matches.small ? sanitizedBannerListMobile : sanitizedBannerListDesktop
            }
            name={name!}
          />
        </>
      )
    }
    return null
  }

  return (
    <>
      <Container backgroundColor="none">{renderBanner()}</Container>
    </>
  )
}

export const ActiveBannerWithEB = withErrorBoundary(ActiveBanner, {
  fallback: ({ error }) => (
    <ErrorFallbackUi componentName="ActiveBanner" error={error} />
  ),
})
