import React from "react"
import { useReactiveVar } from "@apollo/client"
import {
  generateProductUrl,
  getDBIdFromGraphqlId,
  GlobalMediaQueryResultInterface,
} from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import { ReactSVG } from "react-svg"
import Link from "@temp/core/link"
import { visitedFrom } from "@temp/@next/globalStates/Ratings/ratingsGlobalState"
import { useRouter } from "next/router"
import { CardContainer } from "../CardContainer"

import * as S from "./styles"
import { CustomImage } from "../../atoms/CustomImage"
import { ButtonStyled } from "../../atoms/Button"
import { ButtonSize, FontSize } from "../../atoms/Button/types"
import { CardStyle } from "../CardContainer/types"
import { Spacer } from "../../atoms/Spacer"
import { LastPurchasedProductCardProps } from "./types"

export const LastPurchasedProductCard: React.FC<LastPurchasedProductCardProps> = ({
  imageUrl = null,
  productName,
  brand,
  productvariant = " ",
  pageType,
  price,
  deliveryRated = false,
  productRated = false,
  uploadUnboxDone = false,
  productvariantflag = false,
  shipmentId,
  productId,
  daysPassed,
}) => {
  const matches: GlobalMediaQueryResultInterface = useReactiveVar(getMedia)
  const router = useRouter()
  const start = productvariant.indexOf("/")

  const showBuyAgain = daysPassed >= 45

  return (
    <>
      <CardContainer cardType={CardStyle.homePageOrdersCard}>
        <S.ProductDetails>
          <S.ImageContainer pageType={pageType} smallScreen={matches.small}>
            {imageUrl ? (
              <CustomImage width={93} height={93} url={imageUrl} removeBg />
            ) : null}
          </S.ImageContainer>
          <S.ProductsInformation>
            <S.ProductHeading smallScreen={matches.small}>
              {productName}
            </S.ProductHeading>
            <S.ProductSubHeading>{brand}</S.ProductSubHeading>
            <S.Price>₹{price}</S.Price>
            {productvariantflag ? (
              <S.ProductSubHeading>
                {productvariant
                  .slice(start + 1)
                  .replace(" ", "")
                  .split("/")
                  .join(" | ")}
              </S.ProductSubHeading>
            ) : (
              <S.ProductSubHeading>
                {productvariant.replace(" ", "").split("/").join(" | ")}
              </S.ProductSubHeading>
            )}
          </S.ProductsInformation>
          {!matches.small && (
            <S.RightColumn
              showReviewPending={
                showBuyAgain && (!uploadUnboxDone || !deliveryRated || !productRated)
              }
            >
              <S.Coupons>
                <ReactSVG src="/images/home/unlock_coupons.svg" />
                <S.UnlockCouponsText>Unlock Coupons</S.UnlockCouponsText>
              </S.Coupons>

              {(daysPassed < 45 || daysPassed === "Just Now") && (
                <>
                  <Link
                    href={
                      !uploadUnboxDone
                        ? `/product/edit-review/unboxing?shipmentid=${getDBIdFromGraphqlId(
                            shipmentId
                          )}&productid=${getDBIdFromGraphqlId(productId)}&step=1`
                        : ""
                    }
                  >
                    <ButtonStyled
                      onClick={() => visitedFrom(router.pathname)}
                      fontSize={FontSize.caption}
                      buttonSize={ButtonSize.small}
                      buttonStyle={
                        uploadUnboxDone ? "unboxingUploaded" : "unboxingWithIcon"
                      }
                      iconName={uploadUnboxDone ? "greenTick" : "camera"}
                    >
                      {uploadUnboxDone ? "Unboxing Uploaded" : "Upload Unboxing"}
                    </ButtonStyled>
                  </Link>
                  <Spacer spacerStyle="halfSpacer" />
                </>
              )}

              <Link
                href={
                  showBuyAgain
                    ? `${generateProductUrl(productId, productName)}`
                    : deliveryRated
                    ? `/product/edit-review/productrating?shipmentid=${getDBIdFromGraphqlId(
                        shipmentId
                      )}&productid=${getDBIdFromGraphqlId(productId)}&step=5`
                    : `/product/edit-review/deliveryrating?shipmentid=${getDBIdFromGraphqlId(
                        shipmentId
                      )}&productid=${getDBIdFromGraphqlId(productId)}&step=3`
                }
              >
                <ButtonStyled
                  onClick={() => visitedFrom(router.pathname)}
                  fontSize={FontSize.caption}
                  buttonSize={ButtonSize.small}
                  iconName="greenTick"
                  buttonStyle={
                    showBuyAgain
                      ? "primary"
                      : !deliveryRated
                      ? "rateDelivery"
                      : !productRated
                      ? "rateProduct"
                      : "unboxingUploaded"
                  }
                >
                  {showBuyAgain
                    ? "Buy Again"
                    : !deliveryRated
                    ? "Rate Delivery"
                    : !productRated
                    ? "Rate Product"
                    : "Product Rated"}
                </ButtonStyled>
              </Link>
              <Spacer spacerStyle="halfSpacer" />
              {showBuyAgain &&
                (!uploadUnboxDone || !deliveryRated || !productRated) && (
                  <>
                    <S.ReviewPending>
                      <ReactSVG src="/images/order/warning.svg" />
                      Review Pending
                      <Link
                        href={
                          !uploadUnboxDone
                            ? `/product/edit-review/unboxing?shipmentid=${getDBIdFromGraphqlId(
                                shipmentId
                              )}&productid=${getDBIdFromGraphqlId(productId)}&step=1`
                            : !productRated
                            ? `/product/edit-review/productrating?shipmentid=${getDBIdFromGraphqlId(
                                shipmentId
                              )}&productid=${getDBIdFromGraphqlId(productId)}&step=5`
                            : `/product/edit-review/deliveryrating?shipmentid=${getDBIdFromGraphqlId(
                                shipmentId
                              )}&productid=${getDBIdFromGraphqlId(productId)}&step=3`
                        }
                      >
                        Rate Now
                      </Link>
                    </S.ReviewPending>
                  </>
                )}
            </S.RightColumn>
          )}
        </S.ProductDetails>
        {matches.small && (
          <>
            <Spacer />
            {(daysPassed < 45 || daysPassed === "Just Now") && (
              <>
                <Link
                  href={
                    !uploadUnboxDone
                      ? `/product/edit-review/unboxing?shipmentid=${getDBIdFromGraphqlId(
                          shipmentId
                        )}&productid=${getDBIdFromGraphqlId(productId)}&step=1`
                      : ""
                  }
                >
                  <ButtonStyled
                    onClick={() => visitedFrom(router.pathname)}
                    fontSize={FontSize.caption}
                    buttonSize={ButtonSize.small}
                    // buttonStyle="rateDelivery"
                    buttonStyle={
                      uploadUnboxDone ? "unboxingUploaded" : "unboxingWithIcon"
                    }
                    iconName={uploadUnboxDone ? "greenTick" : "camera"}
                  >
                    {uploadUnboxDone ? "Unboxing Uploaded" : "Upload Unboxing"}
                  </ButtonStyled>
                </Link>
                <Spacer spacerStyle="halfSpacer" />
              </>
            )}
            <Link
              href={
                showBuyAgain
                  ? `${generateProductUrl(productId, productName)}`
                  : deliveryRated
                  ? `/product/edit-review/productrating?shipmentid=${getDBIdFromGraphqlId(
                      shipmentId
                    )}&productid=${getDBIdFromGraphqlId(productId)}&step=5`
                  : `/product/edit-review/deliveryrating?shipmentid=${getDBIdFromGraphqlId(
                      shipmentId
                    )}&productid=${getDBIdFromGraphqlId(productId)}&step=3`
              }
            >
              <ButtonStyled
                onClick={() => visitedFrom(router.pathname)}
                fontSize={FontSize.caption}
                buttonSize={ButtonSize.small}
                iconName="greenTick"
                buttonStyle={
                  showBuyAgain
                    ? "primary"
                    : !deliveryRated
                    ? "rateDelivery"
                    : !productRated
                    ? "rateProduct"
                    : "unboxingUploaded"
                }
              >
                {showBuyAgain
                  ? "Buy Again"
                  : !deliveryRated
                  ? "Rate Delivery"
                  : !productRated
                  ? "Rate Product"
                  : "Product Rated"}
              </ButtonStyled>
            </Link>
            {showBuyAgain && (!uploadUnboxDone || !deliveryRated || !productRated) && (
              <>
                <Spacer />
                <S.ReviewPending>
                  <ReactSVG src="/images/order/warning.svg" />
                  Review Pending
                  <Link
                    href={
                      !uploadUnboxDone
                        ? `/product/edit-review/unboxing?shipmentid=${getDBIdFromGraphqlId(
                            shipmentId
                          )}&productid=${getDBIdFromGraphqlId(productId)}&step=1`
                        : !productRated
                        ? `/product/edit-review/productrating?shipmentid=${getDBIdFromGraphqlId(
                            shipmentId
                          )}&productid=${getDBIdFromGraphqlId(productId)}&step=5`
                        : `/product/edit-review/deliveryrating?shipmentid=${getDBIdFromGraphqlId(
                            shipmentId
                          )}&productid=${getDBIdFromGraphqlId(productId)}&step=3`
                    }
                  >
                    Rate Now
                  </Link>
                </S.ReviewPending>
              </>
            )}
          </>
        )}
      </CardContainer>
    </>
  )
}
