import { useApolloClient } from "@apollo/client"
import { HomePageNextProps } from "@components/templates/HomePage/types"
import { homepageActiveLayoutQuery } from "@temp/@next/staticProps/home/query"
import { HomepageActiveLayout } from "@temp/@next/staticProps/home/types/HomepageActiveLayout"
import { TrendingProductsWithEB } from "@temp/components/TrendingProducts/TrendingProducts"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import React from "react"
import LazyHydrate from "react-lazy-hydration"
import { LayoutLayoutLineTypeChoices, LayoutPlatform } from "types/globalTypes"
import { BrandsFeaturedWithEB } from "../../atoms/BrandsFeatured"
import { LatestOrderWithEB } from "../../atoms/LatestOrder/LatestOrder"
import { Loader } from "../../atoms/Loader"
import { ProductsByExclusiveWithEB } from "../../atoms/ProductsByExclusive/ProductsByExclusive"
import { ProductsByGoalWithEB } from "../../atoms/ProductsByGoal/ProductsByGoal"
import { Spacer } from "../../atoms/Spacer"
import { ActiveBannerWithEB } from "../../organisms/ActiveBanner/ActiveBanner"
import { CategoryCardWithEB } from "../../organisms/CategoryCards"
import { HomepageMetaWrapper } from "../../organisms/HomepageMetaWrapper/HomepageMetaWrapper"
import { PurchasedOrdersSectionEB } from "../../organisms/PurchasedOrdersSection"

import * as S from "./styles"

export const HomePage: React.FC<HomePageNextProps> = (props) => {
  const { exclusiveBrands, widthNumberArray } = props
  const matches: GlobalMediaQueryResultInterface = getMedia()
  const apolloClient = useApolloClient()

  const activeLayout = apolloClient.readQuery<HomepageActiveLayout>({
    query: homepageActiveLayoutQuery,
    variables: {
      platform: matches.small ? LayoutPlatform.MOBILE : LayoutPlatform.DESKTOP,
    },
  })

  // return loader if active layout is null
  if (!activeLayout?.activeLayout?.id) {
    return <Loader />
  }

  const renderHomePageLayout = () => {
    if (
      activeLayout.activeLayout?.lines &&
      activeLayout.activeLayout?.lines?.length > 0
    ) {
      const homepageLayout = activeLayout.activeLayout.lines!.map(
        (layout, index) => {
          switch (layout?.type) {
            // overall trending products slider
            case LayoutLayoutLineTypeChoices.OVERALL_TRENDING: {
              return (
                <React.Fragment key={layout.id}>
                  <TrendingProductsWithEB />
                  <Spacer
                    spacerStyle={
                      matches.small ? "spacerTriple" : "v2ThemeSpaceMedium"
                    }
                  />
                </React.Fragment>
              )
            }
            // trending brands (Featured Brands section)
            case LayoutLayoutLineTypeChoices.TRENDING_BRANDS: {
              return (
                <React.Fragment key={layout.id}>
                  <LazyHydrate whenVisible>
                    <S.HomepageContainerPadding>
                      <BrandsFeaturedWithEB widthNumberArray={widthNumberArray} />
                    </S.HomepageContainerPadding>
                  </LazyHydrate>
                  <Spacer
                    spacerStyle={
                      matches.small ? "spacerTriple" : "v2ThemeSpaceMedium"
                    }
                  />
                </React.Fragment>
              )
            }
            // Active Banner list
            case LayoutLayoutLineTypeChoices.BANNER_LIST: {
              return (
                <React.Fragment key={layout.id}>
                  <LazyHydrate whenVisible>
                    <ActiveBannerWithEB
                      preload={index === 0}
                      id={layout.objectId!}
                    />
                  </LazyHydrate>
                  <Spacer
                    spacerStyle={matches.small ? "spacerMedium" : "spacerDouble"}
                  />
                </React.Fragment>
              )
            }
            // Brand slider
            case LayoutLayoutLineTypeChoices.BRAND: {
              return (
                <React.Fragment key={layout.id}>
                  <LazyHydrate whenVisible>
                    <ProductsByGoalWithEB
                      id={layout.objectId!}
                      source={layout.type}
                    />
                  </LazyHydrate>
                  <Spacer
                    spacerStyle={
                      matches.small ? "spacerTriple" : "v2ThemeSpaceMedium"
                    }
                  />
                </React.Fragment>
              )
            }
            // Category slider
            case LayoutLayoutLineTypeChoices.CATEGORY: {
              return (
                <React.Fragment key={layout.id}>
                  <LazyHydrate whenVisible>
                    <ProductsByGoalWithEB
                      id={layout.objectId!}
                      source={layout.type}
                    />
                  </LazyHydrate>
                  <Spacer
                    spacerStyle={
                      matches.small ? "spacerTriple" : "v2ThemeSpaceMedium"
                    }
                  />
                </React.Fragment>
              )
            }
            // Collection slider
            case LayoutLayoutLineTypeChoices.COLLECTION: {
              return (
                <React.Fragment key={layout.id}>
                  <LazyHydrate whenVisible>
                    <ProductsByGoalWithEB
                      id={layout.objectId!}
                      source={layout.type}
                    />
                  </LazyHydrate>
                  <Spacer
                    spacerStyle={
                      matches.small ? "spacerTriple" : "v2ThemeSpaceMedium"
                    }
                  />
                </React.Fragment>
              )
            }
            // Exclusive on buyceps section
            case LayoutLayoutLineTypeChoices.EXCLUSIVE_ON_BUYCEPS: {
              return (
                <React.Fragment key={layout.id}>
                  <LazyHydrate whenVisible>
                    <ProductsByExclusiveWithEB exclusiveBrands={exclusiveBrands!} />
                  </LazyHydrate>
                  <Spacer
                    spacerStyle={
                      matches.small ? "spacerTriple" : "v2ThemeSpaceMedium"
                    }
                  />
                </React.Fragment>
              )
            }
            // default
            default: {
              return null
            }
          }
        }
      )
      homepageLayout.splice(
        1,
        0,
        <React.Fragment key="uniqueKeyConstraint">
          <PurchasedOrdersSectionEB />
          <Spacer spacerStyle={matches.small ? "spacerTriple" : "spacerDouble"} />
        </React.Fragment>
      )
      return homepageLayout
    }
    return null
  }
  return (
    <S.HomePage>
      {/* meta wrapper */}
      <HomepageMetaWrapper />

      {/* Category cards slider on mobile only */}
      {matches.small && <CategoryCardWithEB />}

      {/* Spacer before first section */}
      <S.NoFlex>
        <Spacer spacerStyle={matches.small ? "spacer" : "spacerTriple"} />
      </S.NoFlex>

      {/* <PurchasedOrdersSection /> */}
      {/* Render homepage component based on active layout */}
      {renderHomePageLayout()}

      <LatestOrderWithEB />
    </S.HomePage>
  )
}
