import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const Spacer = styled.div`
  margin-bottom: ${C.spacer + 0.5}rem;
  @media (max-width: ${C.smallScreen}px) {
    margin-bottom: ${C.spacer}rem;
    margin-left: 10px;
  }
`
Spacer.displayName = "S.Spacer"

export const ProductCardsSlider = styled.div<{ marginTop?: number }>`
  margin-top: 0;
`
ProductCardsSlider.displayName = "S.ProductCardsSlider"

export const ExploreAll = styled.h6`
  font-weight: 500;
  font-size: 13px;
  text-decoration: underline;
`
ExploreAll.displayName = "S.ExploreAll"
