import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const TrendingHeading = styled.h3`
  @media (min-width: ${C.smallScreen}px) {
    font-size: ${C.v2Theme.fontSizes.heading};
  }
  font-size: ${C.v2Theme.fontSizes.subHeading};
  color: ${C.v2Theme.backgroundColors.black};
  font-weight: ${C.v2Theme.fontWeights.semiBold};
  line-height: 1;
`
TrendingHeading.displayName = "S.TrendingHeading"
