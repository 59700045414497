import styled from "styled-components"
import { smallScreen } from "@temp/@next/globalStyles/constants"

export const HomePage = styled.div`
  flex: unset !important;
  margin: unset !important;
  .slider {
    height: auto !important;

    @media (min-width: $x-small-screen) {
      min-height: $x-small-screen-banner-height;
    }

    @media (min-width: $small-screen) {
      min-height: $small-screen-banner-height;

      .slider-control-bottomcenter {
        display: none;
      }
    }

    @media (min-width: $medium-screen) {
      min-height: $medium-screen-banner-height;
    }
  }
`
HomePage.displayName = "S.HomePage"

export const HomepageContainerPadding = styled.div`
  @media (max-width: ${smallScreen}px) {
    padding: 0 10px;
  }
`

export const NoFlex = styled.div`
  flex: unset;
  margin: unset;
`
