import { useApolloClient } from "@apollo/client"
import { MemoizedProductCardsSlider } from "@components/organisms/ProductCardsSlider/ProductCardsSlider"
import { withErrorBoundary } from "@sentry/nextjs"
import { ErrorFallbackUi } from "@temp/@next/components/molecules/ErrorFallbackUi/ErrorFallbackUi"
import { trendingProductIds } from "@temp/components/TrendingProducts/queries"
import { TrendingProductIds } from "@temp/components/TrendingProducts/types/TrendingProductIds"
import React, { memo } from "react"

const TrendingProducts: React.FC = () => {
  const apolloClient = useApolloClient()
  const shop = apolloClient.readQuery<TrendingProductIds>({
    query: trendingProductIds,
  })
  const productList: any = []
  shop?.shop?.homepageCollection?.purchasables?.edges.forEach((edge) => {
    const purchasable = edge.node.comboProduct ?? edge.node.product
    productList.push({
      productid: purchasable!.id,
      iscomboProduct: !!edge.node.comboProduct,
    })
  })

  return (
    <MemoizedProductCardsSlider
      title="Trending Products"
      productIds={productList}
      arrowId={0}
      subheading={false}
    />
  )
}

const MemoizedTrendingProducts = memo(TrendingProducts)

export const TrendingProductsWithEB = withErrorBoundary(MemoizedTrendingProducts, {
  fallback: ({ error }) => (
    <ErrorFallbackUi componentName="TrendingProducts" error={error} />
  ),
})
