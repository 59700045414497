import { v2Theme } from "@temp/@next/globalStyles/constants"
import styled from "styled-components"

export const CarouselCardContainer = styled.div<{
  height: number
}>`
  height: ${(props) => props.height}px;
  margin: 0 auto;
  overflow: hidden;
  background: ${v2Theme.backgroundColors.gradients1};
  position: relative;
`
