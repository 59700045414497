import {
  MiniBanners_bannerList_desktopSize,
  MiniBanners_bannerList_lines,
  MiniBanners_bannerList_mobileSize,
} from "@temp/@next/staticProps/home/types/MiniBanners"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import dynamic from "next/dynamic"

import React from "react"
import { BannerSliderDesktopProps } from "../BannerSliderDesktop/BannerSliderDesktop"
import { BannerSliderMobileProps } from "../BannerSliderMobile/BannerSliderMobile"

const BannerSliderDesktop = dynamic<BannerSliderDesktopProps>(() =>
  import("../BannerSliderDesktop/BannerSliderDesktop").then(
    (module) => module.BannerSliderDesktop
  )
)
const BannerSliderMobile = dynamic<BannerSliderMobileProps>(() =>
  import("../BannerSliderMobile/BannerSliderMobile").then(
    (module) => module.BannerSliderMobile
  )
)

interface BannerSliderProps {
  desktopSize: MiniBanners_bannerList_desktopSize
  mobileSize: MiniBanners_bannerList_mobileSize
  bannerList: (MiniBanners_bannerList_lines | null)[]
  id: string
  name: string
}

export const BannerSlider: React.FC<BannerSliderProps> = (
  props: BannerSliderProps
) => {
  const { desktopSize, mobileSize, bannerList, id, name } = props
  const matches: GlobalMediaQueryResultInterface = getMedia()

  return (
    <>
      {matches.small ? (
        <BannerSliderMobile
          name={name}
          size={mobileSize!}
          bannerList={bannerList!}
        />
      ) : (
        <BannerSliderDesktop
          name={name}
          id={id}
          size={desktopSize!}
          bannerList={bannerList!}
        />
      )}
    </>
  )
}
