import { useApolloClient } from "@apollo/client"
import { homePageQuery } from "@temp/@next/staticProps/home/query"
import { HomeShop } from "@temp/@next/staticProps/home/types/HomeShop"
import { structuredData } from "@temp/core/SEO/Homepage/structuredData"
import React from "react"
import { MetaWrapper } from "../MetaWrapper"

export const HomepageMetaWrapper = () => {
  const apolloClient = useApolloClient()
  const homeShopData = apolloClient.readQuery<HomeShop>({
    query: homePageQuery,
  })
  const title = homeShopData?.shop?.name
  const description = homeShopData?.shop?.description

  // og and twitter data
  const openGraph = [
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:url",
      content: typeof window !== "undefined" ? window.location.href : undefined,
    },
    {
      property: "og:title",
      content: homeShopData!.shop!.name ?? undefined,
    },
    {
      property: "og:description",
      content: homeShopData!.shop!.description ?? undefined,
    },
    {
      property: "og:image",
      content: "https://cdn.buyceps.com/bnr_images/home_image_2x.png",
    },
  ]
  const twitter = [
    {
      property: "twitter:card",
      content: "summary_large_image",
    },
    {
      property: "twitter:url",
      content: typeof window !== "undefined" ? window.location.href : undefined,
    },
    {
      property: "twitter:title",
      content: homeShopData!.shop!.name ?? undefined,
    },
    {
      property: "twitter:description",
      content: homeShopData!.shop!.description ?? undefined,
    },
    {
      property: "twitter:image",
      content: "https://cdn.buyceps.com/bnr_images/home_image_2x.png",
    },
  ]

  // structured data
  const googleStructuredData = structuredData(homeShopData!.shop)

  return (
    <MetaWrapper
      title={title}
      description={description!}
      twitter={twitter}
      openGraph={openGraph}
      googleStructuredData={googleStructuredData}
    />
  )
}
