import { useApolloClient } from "@apollo/client"
import { withErrorBoundary, captureException } from "@sentry/nextjs"
import {
  getCollectionQuery,
  trendingProductsQuery,
} from "@temp/@next/staticProps/home/query"
import { GetCollectionData } from "@temp/@next/staticProps/home/types/GetCollectionData"
import { TrendingProductList } from "@temp/@next/staticProps/home/types/TrendingProductList"
import { getGraphqlIdFromDBId, slugify } from "@temp/core/utils"
import React, { memo } from "react"
import { LayoutLayoutLineTypeChoices } from "types/globalTypes"
import { ErrorFallbackUi } from "../../molecules/ErrorFallbackUi/ErrorFallbackUi"

import { MemoizedProductCardsSlider } from "../../organisms/ProductCardsSlider/ProductCardsSlider"
import * as S from "./styles"

interface ProductsByGoalsProps {
  id: number
  source: LayoutLayoutLineTypeChoices
}

const ProductsByGoal: React.FC<ProductsByGoalsProps> = (
  props: ProductsByGoalsProps
) => {
  const { id, source } = props

  const variables: { [key: string]: string[] } = {
    categories: [],
    brands: [],
    collections: [],
  }

  switch (source) {
    case LayoutLayoutLineTypeChoices.BRAND:
      variables.brands.push(getGraphqlIdFromDBId(id!.toString(), "Brand"))
      break;
    case LayoutLayoutLineTypeChoices.COLLECTION:
      variables.collections.push(getGraphqlIdFromDBId(id!.toString(), "Collection"))
      break;
    case LayoutLayoutLineTypeChoices.CATEGORY:
      variables.categories.push(getGraphqlIdFromDBId(id!.toString(), "Category"))
      break;
    default:
      break;
  }

  const apolloClient = useApolloClient()
  const productData = apolloClient.readQuery<TrendingProductList>({
    query: trendingProductsQuery,
    variables,
  })
  if (Object.prototype.hasOwnProperty.call(productData, 'errors') || !productData || !productData.purchasables?.edges?.length) {
    const errorMessage = ["Unexpected response received in the trendingProductsQuery query",
      `variables: ${JSON.stringify(variables)}.`,
      `Response: ${JSON.stringify(productData)}.`,
      "Source file: apps/storefront/src/@next/components/atoms/ProductsByGoal/ProductsByGoal.tsx"].join("\n")
    console.error(errorMessage)
    captureException(new Error(errorMessage))
    return null
  }
  const productList: any = []
  for (const edge of productData?.purchasables?.edges!) {
    const purchasable = edge.node.comboProduct ?? edge.node.product
    productList.push({
      productid: purchasable!.id,
      iscomboProduct: !!edge.node.comboProduct,
    })
  }
  const productListItems = productData?.purchasables?.edges
  const productType =
    productListItems![0].node.comboProduct ?? productListItems![0].node.product
  let title = ""
  let href = ""
  if (source === LayoutLayoutLineTypeChoices.BRAND) {
    title = `Trending in ${productType!.brand?.name!}`
    href = `/brand/${slugify(productType!.brand?.name!)}/${id}`
  }
  if (source === LayoutLayoutLineTypeChoices.CATEGORY) {
    const encodedId = getGraphqlIdFromDBId(id!.toString(), "Category")
    if (productType!.category!.id === encodedId) {
      title = `Trending in ${productType!.category!.name!}`
      href = `/category/${slugify(productType!.category!.name!)}/${id}`
    } else if (productType!.category?.parent?.id === encodedId) {
      title = `Trending in ${productType!.category?.parent?.name!}`
      href = `/category/${slugify(productType!.category?.parent?.name!)}/${id}`
    }
  }
  if (source === LayoutLayoutLineTypeChoices.COLLECTION) {
    const collectionData = apolloClient.readQuery<GetCollectionData>({
      query: getCollectionQuery,
      variables: {
        id: getGraphqlIdFromDBId(id!.toString(), "Collection"),
      },
    })
    href = `/collection/${slugify(collectionData?.collection?.name!)}/${id}`
    title = collectionData?.collection?.name!
  }

  return (
    <S.ProductsByGoal>
      <MemoizedProductCardsSlider
        title={title}
        listId={id}
        productIds={productList}
        arrowId={id}
        href={href}
        subheading
      />
    </S.ProductsByGoal>
  )
}

const MemoizedProductsByGoal = memo(ProductsByGoal)
export const ProductsByGoalWithEB = withErrorBoundary(MemoizedProductsByGoal, {
  fallback: ({ error }) => (
    <ErrorFallbackUi componentName="ProductsByGoal" error={error} />
  ),
})
