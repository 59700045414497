import { useReactiveVar } from "@apollo/client"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import React from "react"
// import Swiper and required modules
import { Navigation } from "swiper"
// eslint-disable-next-line import/no-unresolved
import { Swiper } from "swiper/react"

import { CarouselType } from "./types"

// install Swiper modules
export const Carousel: React.FC<CarouselType> = ({
  nextRef,
  prevRef,
  slides = 4,
  spacing = null,
  children,
}) => {
  const matches: GlobalMediaQueryResultInterface = useReactiveVar(getMedia)

  const carousel = (slidesCarousel: number, cellSpacing: number) => (
    <Swiper
      modules={[Navigation]}
      slidesPerView={slidesCarousel}
      slidesPerGroup={slides === 4 ? 4 : slides === 1 ? 1 : 2}
      navigation={{
        prevEl: `.${prevRef}`,
        nextEl: `.${nextRef}`,
      }}
      spaceBetween={cellSpacing}
      className="ProductsSlider"
    >
      {children}
    </Swiper>
  )

  return (
    <>
      {matches.large
        ? carousel(slides, spacing || 24)
        : matches.medium && carousel(3, 8)}
    </>
  )
}
