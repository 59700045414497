import { gql } from "@apollo/client"
import { comboProductCardFragment } from "@temp/@next/components/organisms/ProductCard/queries"
import { basicProductFragment } from "../product/queries"

export const homepageActiveLayoutQuery = gql`
  query HomepageActiveLayout($platform: LayoutPlatform) {
    activeLayout(platform: $platform) {
      id
      name
      mobileDefault
      desktopDefault
      lines {
        id
        objectId
        type
      }
    }
  }
`

export const trendingProductsSSRProps = gql`
  query TrendingProductsSSRProps {
    shop {
      homepageCollection {
        id
        purchasables(first: 20) {
          edges {
            node {
              id
              product {
                ...BasicProductFields
                pricing {
                  displayPrice {
                    amount
                  }
                  displayListPrice {
                    amount
                  }
                }
                category {
                  id
                  name
                }
              }
              comboProduct {
                ...ComboProductCardFragment
                pricing {
                  displayPrice {
                    amount
                  }
                  displayListPrice {
                    amount
                  }
                }
                category {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  ${basicProductFragment}
  ${comboProductCardFragment}
`

export const miniBannersQuery = gql`
  query MiniBanners($id: ID!) {
    bannerList(id: $id) {
      id
      name
      description
      showNameOnStorefront
      mobileSize {
        height
        width
        carousel
      }
      desktopSize {
        height
        width
        carousel
      }
      lines {
        id
        name
        mobileImage
        desktopImage
        imageAltText
        finalUrl
      }
    }
  }
`

export const getCollectionQuery = gql`
  query GetCollectionData($id: ID!) {
    collection(id: $id) {
      id
      name
    }
  }
`

export const homePageQuery = gql`
  query HomeShop {
    shop {
      description
      name
      homepageCollection {
        id
        backgroundImage {
          url
        }
        name
      }
    }
  }
`

export const trendingProductsQuery = gql`
  query TrendingProductList($categories: [ID], $brands: [ID], $collections: [ID]) {
    purchasables(
      query: ""
      first: 8
      categories: $categories
      brands: $brands
      collections: $collections
      sortBy: { field: POPULARITY, direction: ASC }
    ) {
      edges {
        node {
          id
          product {
            ...BasicProductFields
            pricing {
              priceRange {
                start {
                  gross {
                    amount
                    localized
                  }
                  currency
                }
              }
              listPriceRange {
                start {
                  gross {
                    amount
                    localized
                  }
                  currency
                }
              }
              displayPrice {
                amount
              }
              displayListPrice {
                amount
              }
            }
            category {
              id
              name
              parent {
                id
                name
              }
            }
          }
          comboProduct {
            ...ComboProductCardFragment
            pricing {
              priceRange {
                start {
                  gross {
                    amount
                    localized
                  }
                  currency
                }
              }
              listPriceRange {
                start {
                  gross {
                    amount
                    localized
                  }
                  currency
                }
              }
              displayPrice {
                amount
              }
              displayListPrice {
                amount
              }
            }
          }
        }
      }
    }
  }
  ${basicProductFragment}
  ${comboProductCardFragment}
`
