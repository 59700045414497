import styled from "styled-components"
import { boldFontWeight, thinFontWeight } from "@temp/@next/globalStyles/constants"
import * as C from "@temp/@next/globalStyles/constants"

export const HeadingSpace = styled.div`
  margin: 0 0px ${C.spacing.spacerMedium} 0px;
  @media (max-width: ${C.smallScreen}px) {
    margin: 0px 0px ${C.spacing.spacer} 0px;
    margin-left: 10px;
  }
`

export const ProductsByExclusiveBanner = styled.div`
  position: relative;
  height: 156px;
  img {
    height: 100%;
    position: absolute;
    object-fit: cover;
    width: 100%;
  }
`

export const ProductsByExclusiveBannerH4 = styled.h4`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  padding: 2rem 6rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: ${thinFontWeight};
  line-height: 2.5rem;
`
export const ProductsByExclusiveBannerH4b = styled.b`
  font-size: 2.5rem;
  font-weight: ${boldFontWeight};
`
export const ProductPageExclusiveProductsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
`

ProductPageExclusiveProductsGrid.displayName = "S.ProductPageExclusiveProductsGrid"
