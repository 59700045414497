import { CardContainer } from "@components/molecules/CardContainer"
import { CardStyle } from "@components/molecules/CardContainer/types"
import Link from "@temp/core/link"
import React from "react"
import { CustomImage } from "../CustomImage"

import * as S from "./styles"
import { ProductsByExclusiveListItemProps } from "./types"

export const ProductsByExclusiveListItem: React.FC<ProductsByExclusiveListItemProps> =
  ({ brand, hasScrollParent }) => (
    <CardContainer
      hasScrollParent={hasScrollParent}
      cardType={CardStyle.buycepsExclusive}
    >
      <S.ProductsByExclusiveListItem>
        <Link href={brand.redirectUrl}>
          <a>
            <CustomImage
              url={brand.webpBackgroundUrl}
              alt={brand.alt}
              objectFit="cover"
              layout="fill"
              sizes="20vw"
            />
          </a>
        </Link>
      </S.ProductsByExclusiveListItem>
    </CardContainer>
  )
