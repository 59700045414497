import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const CarouselControlLeft = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  cursor: pointer;
  svg {
    transform: rotate(180deg);
  }
`
CarouselControlLeft.displayName = "S.CarouselControlLeft"

export const CarouselControlRight = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 1;
  cursor: pointer;
`
CarouselControlRight.displayName = "S.CarouselControlRight"

export const PurchasedOrdersWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  column-gap: 10px;
  padding-right: 10px;
  padding-left: 10px;
  scroll-padding-left: 10px;
  padding-top: 31px;
`

export const PurchasedOrderSlide = styled.div`
  scroll-snap-align: start;
  position: relative;
  scroll-behavior: smooth;
  flex-shrink: 0;
  border-radius: 14px;
  z-index: 1;
  background-image: linear-gradient(to bottom, #fefeff, #f8faff 100%);
`

export const Spacer = styled.div`
  @media (max-width: ${C.smallScreen}px) {
    margin-left: 10px;
  }
`
Spacer.displayName = "S.Spacer"
