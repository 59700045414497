export const structuredData = (shop) => {
  const data = {
    "@context": "https://schema.org",
    graph: [
      {
        "@type": "Organization",
        name: shop.name,
        description: shop.description,
        "@id": "https://g.page/BuycepsIndia?share",
        image: "https://cdn.buyceps.com/buyceps/buyceps-logo-300x200.png",
        logo: "https://cdn.buyceps.com/buyceps/buyceps-logo-260x260.png",
        url: "https://buyceps.com",
        telephone: "7004090151",
        sameAs: [
          "https://twitter.com/hashtag/buyceps",
          "https://www.linkedin.com/company/buycepsindia/",
          "https://www.facebook.com/BuycepsIndia/",
          "https://www.instagram.com/buycepsindia/",
        ],
      },
      {
        "@type": "OutletStore",
        name: shop.name,
        description: shop.description,
        "@id": "https://g.page/BuycepsIndia?share",
        image:
          "https://buyceps.com/_next/image?url=%2Fimages%2Fcomponents%2Fbuycepslogo%2Fbuyceps-logo.svg&w=128&q=75",
        logo: "https://buyceps.com/_next/image?url=%2Fimages%2Fcomponents%2Fbuycepslogo%2Fbuyceps-logo.svg&w=128&q=75",
        url: "https://buyceps.com",
        telephone: "7004090151",
        sameAs: [
          "https://twitter.com/hashtag/buyceps",
          "https://www.linkedin.com/company/buycepsindia/",
          "https://www.facebook.com/BuycepsIndia/",
          "https://www.instagram.com/buycepsindia/",
        ],
        address: {
          "@type": "PostalAddress",
          streetAddress:
            "Shop no. 3, Building no. 25, Oshiwara Linkway CHSL, New Link Road Extension, Oshiwara, Andheri West",
          addressLocality: "Mumbai",
          addressRegion: "Maharashtra",
          postalCode: "400053",
          addressCountry: "India",
        },
        openingHoursSpecification: {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ],
          opens: "10:00",
          closes: "19:00",
        },
        priceRange: "₹10-12000",
      },
      {
        "@type": "WebSite",
        url: "https://buyceps.com/",
        potentialAction: {
          "@type": "SearchAction",
          target: {
            "@type": "EntryPoint",
            urlTemplate: "https://buyceps.com/search?q={search_term_string}",
          },
          "query-input": "required name=search_term_string",
        },
      },
    ],
  }
  return JSON.stringify(data)
}
