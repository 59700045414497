import styled, { keyframes } from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const BrandFeaturedContainer = styled.div`
  padding: 2rem 0;
`
export const BrandFeaturedContainerHeader1 = styled.h6`
  text-transform: uppercase;
  font-weight: ${C.thinFontWeight};
  font-size: 1rem;
`

export const BrandFeaturedContainerHeader2 = styled.h3`
  font-weight: ${C.boldFontWeight};
  margin-bottom: ${C.spacing.spacer} * 2;
  text-transform: uppercase;
  font-size: 2rem;
`

export const FeaturedBrands = styled.div<{ smallScreen: boolean }>`
  height: 100%;
  width: 100%;
  ${(props) =>
    props.smallScreen
      ? `
      overflow-x: scroll;
      overflow-y: hidden;
    `
      : `overflow: hidden;`}
  ${(props) => (props.smallScreen ? "padding-left:15px" : "padding:none")};
  position: relative;
`

export const Heading = styled.div`
  line-height: 1;
  position: sticky;
  left: 0;
  top: 0;
  @media (min-width: ${C.mediumScreen}px) {
    margin-bottom: ${C.spacing.oneAndQuarterSpacer};
  }
  margin-bottom: ${C.spacing.spacer};
`
Heading.displayName = "S.Heading"

const brandslide = keyframes`
{
  0%   { transform: translateX(-14%); }
  10%  { transform: translateX(-14%); }
  30%  { transform: translateX(-14%); }
  50%  { transform: translateX(-14%); }
  57%  { transform: translateX(-14%); }
  64%  { transform: translateX(-14%); }
  100% { transform: translateX(0); }
}
`
export const FeaturedBrandImageUL = styled.ul<{
  desktopWidth: number
  animate: boolean
}>`
  position: relative;
  overflow: hidden;
  width: ${(props) => props.desktopWidth - 75}px;
  animation-name: ${(props) => (props.animate ? brandslide : "")};
  animation-duration: 1.3s;
  animation-timing-function: linear;
  @media (max-width: ${C.smallScreen}px) {
    transform: ${(props) => (props.animate ? "" : "translateX(-14%)")};
  }
`
FeaturedBrandImageUL.displayName = "S.FeaturedBrandImageUL"

export const LinkTag = styled.a<{ smallScreen?: boolean }>`
  ${({ smallScreen }) => `
      display: block;
      height: calc(${smallScreen ? "72px" : "72px"} + ${
    smallScreen ? C.spacing.spacer : C.spacing.spacerAndQuarter
  });
    `}
`
LinkTag.displayName = "S.LinkTag"

export const FeaturedBrandImageList = styled.li<{ smallScreen?: boolean }>`
  float: left;
  margin-right: ${(props) =>
    props.smallScreen ? C.spacing.mediumHalfSpacer : C.spacing.spacer};
`
FeaturedBrandImageList.displayName = "S.FeaturedBrandImageList"

export const ImageContainer = styled.div<{ smallScreen?: boolean }>`
  cursor: pointer;
  ${({ smallScreen }) => `
      height: ${smallScreen ? "72px" : "72px"};
      padding: ${smallScreen ? "20px" : "20px"};
      border: 1px solid ${C.v2Theme.backgroundColors.gray4};
      border-radius: 14px;
      background-color: ${C.v2Theme.backgroundColors.white};
    `}
`
ImageContainer.displayName = "S.ImageContainer"

export const WebpImageContainer = styled.source``

export const Image = styled.img`
  height: 100%;
`
Image.displayName = "S.Image"

/* cardcontainer later to be used CardContainer Component */
export const CustomCardContainer = styled.div<{ smallScreen?: boolean }>`
  position: relative;
  overflow: hidden;
  ${({ smallScreen }) => `
      margin: 0 auto;
            padding: 48px 0px 28px 48px;
            border-radius: 14px;
            height: 394px;
            max-width: 1152px;
            background-image: 
              linear-gradient(to bottom, #fefeff, #f8faff 100%);
            ${
              smallScreen &&
              `
                padding: 24px 0px 8px 0px;
                border-radius: 14px;
                height: 330px;
                max-width: 380px;
                margin: 0 auto ;
              `
            }
              `}
`
CustomCardContainer.displayName = "S.CustomCardContainer"
