import { useInView } from "@hooks/useInView"
import {
  MiniBanners_bannerList_desktopSize,
  MiniBanners_bannerList_lines,
  MiniBanners_bannerList_mobileSize,
} from "@temp/@next/staticProps/home/types/MiniBanners"
import { GlobalMediaQueryResultInterface, trackEvent } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import React, { memo, useCallback, useRef } from "react"
import { Autoplay, Pagination } from "swiper"
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react"

import { SwiperStyles } from "../../atoms/Carousel/styles"
import { CarouselCard } from "../../molecules/CarouselCard/CarouselCard"
import * as S from "./styles"
import { ViewPromotionData } from "./utils"

interface CarouselProps {
  desktopSize: MiniBanners_bannerList_desktopSize
  mobileSize: MiniBanners_bannerList_mobileSize
  bannerList: (MiniBanners_bannerList_lines | null)[]
  name: string
  preload: boolean
}

export const BannerCarousel: React.FC<CarouselProps> = (props: CarouselProps) => {
  const { bannerList, desktopSize, mobileSize, name, preload } = props
  const countReference = useRef(0)
  const matches: GlobalMediaQueryResultInterface = getMedia()
  const size = matches.small ? mobileSize : desktopSize

  const callback = useCallback(() => {
    countReference.current = 0
  }, [])

  const carouselReference = useInView({
    threshold: 0.5,
    rootMargin: "0px",
    root: null,
    callback,
  })

  const changeSelectedBannerIndex = (index: number) => {
    if (countReference.current < bannerList.length - 1) {
      const promotionData = ViewPromotionData(bannerList, index, name)
      if (promotionData) {
        trackEvent({
          eventName: "view_promotion",
          eventValue: promotionData,
          sendToPixel: true,
          sendToDataLayer: true,
        })
      }

      countReference.current += 1
    }
  }

  return (
    <SwiperStyles>
      <S.Carousel ref={carouselReference}>
        <Swiper
          onSwiper={(swiper) => {
            if (bannerList.length > 1) {
              if (swiper) swiper.autoplay.stop()
              setTimeout(() => {
                if (!swiper?.destroyed) swiper.autoplay.start()
              }, 10_000)
            }
          }}
          slidesPerView={1}
          modules={[Autoplay, Pagination]}
          loop={bannerList.length > 1}
          speed={800}
          observer
          autoplay={
            bannerList.length > 1
              ? {
                delay: 2500,
                disableOnInteraction: false,
              }
              : false
          }
          draggable
          pagination={
            matches.large && {
              el: ".swiper-pagination",
              clickable: true,
            }
          }
          className="homebanner-slider"
          onActiveIndexChange={(swiper) =>
            changeSelectedBannerIndex(swiper.realIndex)
          }
        >
          <S.CarouselContainerWrapper height={size.height}>
            {bannerList!.map((banner, index) => (
              <SwiperSlide key={banner!.id}>
                <S.CarouselContainer height={size.height}>
                  <CarouselCard
                    image={
                      matches.small ? banner?.mobileImage! : banner?.desktopImage!
                    }
                    alt={banner?.imageAltText!}
                    url={banner?.finalUrl!}
                    size={size}
                    preload={preload && index === 0}
                  />
                </S.CarouselContainer>
              </SwiperSlide>
            ))}
          </S.CarouselContainerWrapper>
        </Swiper>
        {matches.large && (
          <div className="swiper-pagination swiper-pagination-ssr">
            {bannerList!.map((banner) => (
              <span className="swiper-pagination-bullet" key={banner!.id} />
            ))}
          </div>
        )}
      </S.Carousel>
    </SwiperStyles>
  )
}

export const MemoizedCarousel = memo(BannerCarousel)
