import React from "react"

import * as S from "./styles"
import { FadedOverlayProps } from "./types"

export const FadedOverlay: React.FC<FadedOverlayProps> = ({
  position,
  left,
  top,
  right,
  bottom,
  background,
  height,
  width,
  zIndex,
  children,
  brandsfeatured,
}) => {
  return (
    <S.FadedOverlayContainer
      background={background}
      position={position}
      left={left}
      top={top}
      width={width}
      height={height}
      right={right}
      bottom={bottom}
      zIndex={zIndex}
      brandsfeatured={brandsfeatured}
    >
      {children}
    </S.FadedOverlayContainer>
  )
}
