import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"
import { PageType } from "./types"

export const ProductDetails = styled.div`
  display: flex;
  z-index: 1;
  width: 500px;
  align-items: center;
`
const imageContainerSize = {
  desktop: {
    imageContainer: {
      orderDetails: {
        height: "80px",
        width: "80px",
        padding: "12px",
      },
      orderHistory: {
        height: "141px",
        width: "141px",
        padding: "24px",
      },
      marginRight: "24px",
      borderRadius: "14px",
      background: C.v2Theme.backgroundColors.gray3,
    },
  },
  mobile: {
    imageContainer: {
      orderDetails: {
        height: "90px",
        width: "90px",
        padding: "12px",
      },
      orderHistory: {
        height: "90px",
        width: "90px",
        padding: "12px",
      },
      marginRight: "10px",
    },
  },
}

export const ImageContainer = styled.div<{
  pageType: PageType
  smallScreen?: boolean
}>`
  ${({ pageType, smallScreen }) => `
      min-width: ${
        smallScreen
          ? imageContainerSize.mobile.imageContainer[pageType].width
          : imageContainerSize.desktop.imageContainer[pageType].width
      };
      max-width: ${
        smallScreen
          ? imageContainerSize.mobile.imageContainer[pageType].width
          : imageContainerSize.desktop.imageContainer[pageType].width
      };
      margin-right: ${
        smallScreen
          ? imageContainerSize.mobile.imageContainer.marginRight
          : imageContainerSize.desktop.imageContainer.marginRight
      };
      height: ${
        smallScreen
          ? imageContainerSize.mobile.imageContainer[pageType].height
          : imageContainerSize.desktop.imageContainer[pageType].height
      };
      padding: ${imageContainerSize.desktop.imageContainer[pageType].padding};
      background: ${imageContainerSize.desktop.imageContainer.background};
      border-radius: ${imageContainerSize.desktop.imageContainer.borderRadius};
      overflow: hidden;
    `}
`

export const ProductsInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: ${C.smallScreen}px) {
    width: 30%;
  }
  min-width: 0;
`

export const ProductHeading = styled.h6<{ smallScreen?: boolean }>`
  font-size: ${C.v2Theme.fontSizes.title};
  font-weight: ${C.v2Theme.fontWeights.semiBold};
  color: ${C.v2Theme.fontColors.black};
  line-height: 1.25;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ProductSubHeading = styled.h6`
  font-size: ${C.v2Theme.fontSizes.caption};
  font-weight: ${C.v2Theme.fontWeights.regular};
  color: ${C.v2Theme.fontColors.gray1};
  margin-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Price = styled.h6`
  font-size: ${C.v2Theme.fontSizes.paragraph2};
  font-weight: ${C.v2Theme.fontWeights.regular};
  color: #000000;
  margin-top: 4px;
  word-spacing: 4px;
`

export const Coupons = styled.div`
  display: flex;
`

export const UnlockCouponsText = styled.h6`
  font-size: ${C.fontSizeCaptions};
  color: #8492a9;
  font-weight: ${C.semiBoldFontWeight};
  width: 100%;
  margin-left: 5px;
  text-align: left;
`

export const RightColumn = styled.div<{ showReviewPending: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 20px;
  margin-top: ${({ showReviewPending }) => showReviewPending && "-8px"};
`
export const ProcessingText = styled.h2`
  font-size: ${C.v2Theme.fontSizes.subHeading};
  font-weight: ${C.v2Theme.fontWeights.regular};
  color: #000000;
  margin: 8px 0px 0px 4px;
`
export const ReviewPending = styled.div`
  font-size: ${C.v2Theme.fontSizes.caption};
  letter-spacing: 0px;
  font-weight: ${C.normalFontWeight};
  color: #ff5353;
  display: flex;
  justify-content: center;
  /* margin-bottom: 5px; */
  svg {
    margin-right: 3px;
  }
  a {
    margin-left: 10px;
    color: #000000;
    text-decoration: underline;
  }
`
