import { MiniBanners_bannerList_lines } from "@temp/@next/staticProps/home/types/MiniBanners"
import { getDBIdFromGraphqlId } from "@temp/core/utils"

export const ViewPromotionData = (
  banners: (MiniBanners_bannerList_lines | null)[],
  bannerIndex: number,
  name: string
) => {
  // this is how you destructure array for a particular index value
  const { [bannerIndex]: banner } = banners
  if (!banner) {
    return null
  }
  return {
    ecommerce: {
      items: [
        {
          promotion_id: getDBIdFromGraphqlId(
            banner.id ? banner.id : "None",
            "BannerListLine"
          ),
          promotion_name: name ?? "None",
          location_id: "Homepage",
          creative_name: banner.name ? banner.name : "None",
          creative_slot: `Banner:${bannerIndex + 1}`,
        },
      ],
    },
  }
}
