import { useReactiveVar } from "@apollo/client"
import { Container } from "@components/atoms/Container"
import { ProductsByExclusiveProps } from "@components/atoms/ProductsByExclusive/types"
import { TrendingHeading } from "@components/atoms/TrendingHeading"
import { withErrorBoundary } from "@sentry/nextjs"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import React, { memo } from "react"
import { ErrorFallbackUi } from "../../molecules/ErrorFallbackUi/ErrorFallbackUi"

import { TrendingSlide, TrendingWrapper } from "../../organisms/MobileSlider/styles"
import { ProductsByExclusiveListItem } from "../ProductsByExclusiveListItem"
import * as S from "./styles"

const ProductsByExclusive: React.FC<ProductsByExclusiveProps> = ({
  exclusiveBrands,
}) => {
  const matches: GlobalMediaQueryResultInterface = useReactiveVar(getMedia)

  return (
    <>
      <Container backgroundColor="none">
        <S.HeadingSpace>
          <TrendingHeading heading="Exclusive on Buyceps" />
        </S.HeadingSpace>
        {matches.small ? (
          <TrendingWrapper>
            {exclusiveBrands.map((brand) => (
              <TrendingSlide key={brand.alt}>
                <ProductsByExclusiveListItem hasScrollParent brand={brand} />
              </TrendingSlide>
            ))}
          </TrendingWrapper>
        ) : (
          <S.ProductPageExclusiveProductsGrid>
            {exclusiveBrands.map((brand) => (
              <ProductsByExclusiveListItem key={brand.alt} brand={brand} />
            ))}
          </S.ProductPageExclusiveProductsGrid>
        )}
      </Container>
    </>
  )
}

const MemoizedProductsByExclusive = memo(ProductsByExclusive)
export const ProductsByExclusiveWithEB = withErrorBoundary(
  MemoizedProductsByExclusive,
  {
    fallback: ({ error }) => (
      <ErrorFallbackUi componentName="ProductsByExclusive" error={error} />
    ),
  }
)
