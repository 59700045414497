import { useQuery, useReactiveVar } from "@apollo/client"
import React, { memo } from "react"
// eslint-disable-next-line import/no-unresolved
import { SwiperSlide } from "swiper/react"
import NavArrow from "public/images/components/carousel/arrow.svg"
import { userGlobalState } from "@temp/@next/globalStates/User/userGlobalState"
import { getMedia } from "@temp/localStates/media"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { withErrorBoundary } from "@sentry/nextjs"
import { Carousel } from "../../atoms/Carousel/Carousel"
import { SwiperStyles } from "../../atoms/Carousel/styles"
import { LastPurchasedProductCard } from "../../molecules/LastPurchasedProductCard"

import * as S from "./styles"
import { Container } from "../../atoms/Container"
import { TrendingHeading } from "../../atoms/TrendingHeading"
import { Spacer } from "../../atoms/Spacer"
import { deepSearchByKey, timeSinceDays } from "../utils"
import { getLastPurchasedOrdersQuery, getLastPurchasedReviewsQuery } from "./queries"
import { ErrorFallbackUi } from "../../molecules/ErrorFallbackUi/ErrorFallbackUi"
import { getLastPurchased } from "./types/getLastPurchased"
import { getLastPurchasedReviews } from "./types/getLastPurchasedReviews"

const PurchasedOrdersSection: React.FC<{}> = () => {
  const { user } = useReactiveVar(userGlobalState)
  const { data: userOrders } = useQuery<getLastPurchased>(
    getLastPurchasedOrdersQuery,
    {
      skip: !user,
      fetchPolicy: "network-only",
    }
  )
  const matches: GlobalMediaQueryResultInterface = useReactiveVar(getMedia)
  const ordersDelivered = []
  if (userOrders?.me?.lastYearOrders?.length! > 0) {
    for (const order of userOrders?.me?.lastYearOrders!) {
      const orderDeliveredNode = order?.shipments!.find(
        (shipment) => shipment?.deliveryStatus === "DELIVERED"
      )
      if (orderDeliveredNode) {
        ordersDelivered.push({
          shipmentId: orderDeliveredNode.id,
          orderDeliveredNode,
          deliveryRating: orderDeliveredNode.deliveryReview?.rating,
          created: order?.created,
        })
      }
    }
  }
  const allOrderLines = []
  for (const order of ordersDelivered) {
    allOrderLines.push({
      orderLine: deepSearchByKey(
        order?.orderDeliveredNode?.fulfillments,
        "orderLine"
      ),
      shipmentId: order.shipmentId,
      created: order?.created?.value,
      deliveryRating: order.deliveryRating,
    })
  }
  const lastPurchasedObject = []
  for (const odLine of allOrderLines) {
    for (const odLine1 of odLine.orderLine) {
      lastPurchasedObject.push({
        variantName: odLine1.orderLine.variant.name,
        productName: odLine1.orderLine.variant.product.name,
        productId: odLine1.orderLine.variant.product.id,
        brand: odLine1.orderLine.variant.product.brand.name,
        price: odLine1.orderLine.variant.pricing.price.gross.amount,
        image: odLine1.orderLine.variant.product.firstImage.url,
        shipmentId: odLine.shipmentId,
        orderCreated: odLine.created,
        deliveryRated: odLine.deliveryRating,
        isPublished: odLine1.orderLine.variant.isPublished,
      })
    }
  }
  const key = "productId"
  const arrayUniqueByKey = [
    ...new Map(lastPurchasedObject.map((item) => [item[key], item])).values(),
  ]
  const swiperNavClassPrevious = `productByGoal-slider-${1}-prev`
  const swiperNavClassNext = `productByGoal-slider-${2}-next`
  const productIds = arrayUniqueByKey.map((row) => row.productId)

  const { data: userReviews } = useQuery<getLastPurchasedReviews>(
    getLastPurchasedReviewsQuery,
    {
      variables: {
        productIds,
      },
      skip: productIds.length !== arrayUniqueByKey.length || !user,
    }
  )
  const lastPurchasedObjectWithReview = []
  for (const line of arrayUniqueByKey) {
    lastPurchasedObjectWithReview.push({
      ...line,
      productRated:
        userReviews?.me?.productReviews?.edges?.filter(
          (edge) => edge?.node?.product?.id === line?.productId
        ).length! > 0 &&
        userReviews?.me?.productReviews?.edges?.filter(
          (edge) => edge?.node?.product?.id === line?.productId
        )[0]?.node?.recommends !== null,

      uploadUnboxDone:
        userReviews?.me?.productReviews?.edges?.filter(
          (edge) => edge?.node?.product?.id === line?.productId
        ).length! > 0 &&
        userReviews?.me?.productReviews?.edges?.filter(
          (edge) => edge?.node?.product?.id === line?.productId
        )[0]?.node?.unboxingMedias?.edges?.length! > 0,

      isPublished: line.isPublished,

      daysPassed: timeSinceDays(new Date(line.orderCreated)),
    })
  }

  const filteredLastPurchased = lastPurchasedObjectWithReview.filter(
    (object) =>
      object.isPublished &&
      (object.daysPassed >= 45 ||
        !object.uploadUnboxDone ||
        !object.deliveryRated ||
        !object.productRated)
  )

  return userOrders &&
    userOrders?.me?.lastYearOrders?.length! > 0 &&
    filteredLastPurchased.length > 0 ? (
    <>
      <Container backgroundColor="none">
        <S.Spacer>
          <TrendingHeading heading="Last Purchased Supplements" />
        </S.Spacer>
        {matches.large ? (
          <SwiperStyles>
            <Carousel
              slides={2}
              prevRef={swiperNavClassPrevious}
              nextRef={swiperNavClassNext}
            >
              {filteredLastPurchased.slice(0, 11).map((row) => {
                // displaying that of current page number only
                const orders = []
                orders.push(
                  <SwiperSlide key={row?.shipmentId}>
                    <LastPurchasedProductCard
                      key={row?.shipmentId}
                      deliveryRated={row?.deliveryRated}
                      productRated={row?.productRated}
                      uploadUnboxDone={row?.uploadUnboxDone}
                      productName={row?.productName}
                      brand={row?.brand}
                      price={row?.price}
                      productvariant={row?.variantName}
                      pageType="orderDetails"
                      imageUrl={row?.image}
                      shipmentId={row.shipmentId}
                      productId={row?.productId}
                      orderCreated={row?.orderCreated}
                      daysPassed={row?.daysPassed}
                    />
                  </SwiperSlide>
                )
                // }
                return orders
              })}
            </Carousel>
            <S.CarouselControlLeft className={swiperNavClassPrevious}>
              <NavArrow />
            </S.CarouselControlLeft>
            <S.CarouselControlRight className={swiperNavClassNext}>
              <NavArrow />
            </S.CarouselControlRight>
          </SwiperStyles>
        ) : (
          <S.PurchasedOrdersWrapper>
            {filteredLastPurchased.slice(0, 11).map((row) => {
              // displaying that of current page number only
              const orders = []
              orders.push(
                <S.PurchasedOrderSlide key={row.shipmentId}>
                  <LastPurchasedProductCard
                    key={row?.shipmentId}
                    deliveryRated={row?.deliveryRated}
                    productRated={row?.productRated}
                    uploadUnboxDone={row?.uploadUnboxDone}
                    productName={row?.productName}
                    brand={row?.brand}
                    price={row?.price}
                    productvariant={row?.variantName}
                    pageType="orderDetails"
                    imageUrl={row?.image}
                    shipmentId={row.shipmentId}
                    productId={row?.productId}
                    orderCreated={row?.orderCreated}
                    daysPassed={row?.daysPassed}
                  />
                </S.PurchasedOrderSlide>
              )
              return orders
            })}
          </S.PurchasedOrdersWrapper>
        )}
      </Container>
      <Spacer />
    </>
  ) : null
}

const MemoizedPurchasedOrdersSection = memo(PurchasedOrdersSection)
export const PurchasedOrdersSectionEB = withErrorBoundary(
  MemoizedPurchasedOrdersSection,
  {
    fallback: ({ error }) => (
      <ErrorFallbackUi componentName="PurchasedOrdersSection" error={error} />
    ),
  }
)
