import Link from "@temp/core/link"
import React from "react"
import { TrendingHeading } from "../../atoms/TrendingHeading"
import * as S from "./styles"

type RenderAs = "string" | "link"

interface HomepageSectionHeadingProps {
  heading?: string
  subHeading?: string
  renderAs?: RenderAs
  href?: string
}

export const HomepageSectionHeader: React.FC<HomepageSectionHeadingProps> = ({
  heading,
  subHeading,
  renderAs,
  href,
}: HomepageSectionHeadingProps) => (
  <>
    {heading && (
      <>
        {renderAs === "link" && href ? (
          <>
            <Link href={href}>
              {heading && <TrendingHeading heading={heading} />}
              {subHeading && <S.SubHeading>{subHeading}</S.SubHeading>}
            </Link>
          </>
        ) : (
          <>
            {heading && <TrendingHeading heading={heading} />}
            {subHeading && <S.SubHeading>{subHeading}</S.SubHeading>}
          </>
        )}
      </>
    )}
  </>
)

HomepageSectionHeader.defaultProps = {
  heading: undefined,
  subHeading: undefined,
  href: undefined,
  renderAs: "string",
}
