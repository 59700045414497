import { withErrorBoundary } from "@sentry/nextjs"
import Link from "@temp/core/link"
import React, { memo } from "react"

import { CustomImage } from "../../atoms/CustomImage"
import { ErrorFallbackUi } from "../../molecules/ErrorFallbackUi/ErrorFallbackUi"
import * as S from "./styles"

const homepageStoryMenu = [
  {
    imageUrl: "https://cdn.buyceps.com/navigation/buyceps-nav-protein-icon.jpg",
    name: "Protein",
    url: "/category/proteins/11/",
    alt: "category protein story menu image",
  },
  {
    imageUrl: "https://cdn.buyceps.com/navigation/buyceps-nav-preworkout-icon.jpg",
    name: "Pre Workout",
    url: "/category/pre-workout/3/",
    alt: "category pre workout story menu image",
  },
  {
    imageUrl: "https://cdn.buyceps.com/navigation/buyceps-nav-fatloss-icon.jpg",
    name: "Fat Loss",
    url: "/category/weight-loss-essentials/9/",
    alt: "category weight loss essentials story menu image",
  },
  {
    imageUrl: "https://cdn.buyceps.com/navigation/buyceps-nav-intraworkout-icon.jpg",
    name: "Workout",
    url: "/category/intra-workout/2/",
    alt: "category intra workout story menu image",
  },
  {
    imageUrl: "https://cdn.buyceps.com/navigation/buyceps-nav-massgainer-icon.jpg",
    name: "Mass Gainer",
    url: "/category/mass-gainer/10/",
    alt: "category mass gainer story menu image",
  },
  {
    imageUrl:
      "https://cdn.buyceps.com/navigation/buyceps-nav-speciality-suppliment-icon.jpg",
    name: "Speciality",
    url: "/category/specialty-supplement/6/",
    alt: "category specialty supplement story menu image",
  },
  {
    imageUrl:
      "https://cdn.buyceps.com/navigation/buyceps-nav-body-essential-icon.jpg",
    name: "Essentials",
    url: "/category/body-essentials/4/",
    alt: "category body essentials story menu image",
  },
]

const CategoryCards: React.FC = () => (
  <>
    <S.HomePageScrollContainer>
      <S.HomePageScrollContainerScroller>
        {homepageStoryMenu.map((data) => (
          <S.HomePageScrollContainerScrollerStory
            key={`homepageStoryMenu${data.url}`}
          >
            <S.HomePageScrollContainerScrollerStoryCard>
              <S.ColumnFlexContainer>
                <Link href={data.url}>
                  <a>
                    <CustomImage
                      width={100}
                      height={56}
                      alt={data.alt}
                      url={data.imageUrl || "/images/Product/no-photo.svg"}
                      objectFit="cover"
                    >
                      <S.HomePageScrollContainerScrollerStoryCardImg src="/images/Product/no-photo.svg" />
                    </CustomImage>
                    <S.HomePageScrollContainerScrollerStoryCardH6>
                      <b>{data.name}</b>
                    </S.HomePageScrollContainerScrollerStoryCardH6>
                  </a>
                </Link>
              </S.ColumnFlexContainer>
            </S.HomePageScrollContainerScrollerStoryCard>
          </S.HomePageScrollContainerScrollerStory>
        ))}
      </S.HomePageScrollContainerScroller>
    </S.HomePageScrollContainer>
  </>
)

const MemoizedCategoryCards = memo(CategoryCards)
export const CategoryCardWithEB = withErrorBoundary(MemoizedCategoryCards, {
  fallback: ({ error }) => (
    <ErrorFallbackUi componentName="CategoryCards" error={error} />
  ),
})
